import { FormColorPicker } from '~/Components/Form/Color/ColorPicker';
import { FormAgePicker } from '~/Components/Form/Dates/Age';
import { FormDateTimePicker } from '~/Components/Form/Dates/DateTimePicker';
import { FormEmailInput } from '~/Components/Form/Email';
import { FormPassword } from '~/Components/Form/Password';
import { FormPhoneInput } from '~/Components/Form/PhoneInput/PhoneInput';
import { FormUrlInput } from '~/Components/Form/UrlInput/UrlInput';
import { type FieldType } from '../../../../../db/types';
import { FormCheckbox } from '../../../Form/Checkbox';
import { FormDatePicker } from '../../../Form/Dates/DatePicker';
import { FormInput } from '../../../Form/Input/Input';
import { CardSelect } from '../../../Form/Lookup/CardSelect';
import { FormUserSelect } from '../../../Form/Lookup/UserSelect';
import { FormMultiSelect } from '../../../Form/MultiSelect';
import { FormNumberInput } from '../../../Form/NumberInput';
import { FormRadio } from '../../../Form/Radio';
import { FormSelect } from '../../../Form/Select/Select';
import { FormTextarea } from '../../../Form/Textarea';
import { FormTimeInput } from '../../../Form/Time';

export type FieldDefinition = {
  component: React.FC<any /* TODO improve this type */>;
  options?: boolean;
  span?: 6 | 12;
  multi?: boolean;
  clearable?: boolean;
};

export const mapFieldTypeToFormComponent: Partial<Record<FieldType, FieldDefinition>> = {
  TEXT: { component: FormInput },
  NUMBER: { component: FormNumberInput },
  EMAIL: { component: FormEmailInput },
  URL: { component: FormUrlInput },
  SINGLE_SELECT: { component: FormSelect, options: true, clearable: true },
  CHECKBOX: { component: FormCheckbox, span: 12 },
  MULTI_SELECT: { component: FormMultiSelect, options: true, multi: true },
  TEXTAREA: { component: FormTextarea },
  PHONE: { component: (props) => <FormPhoneInput {...props} /> },
  CURRENCY: { component: (props) => <FormNumberInput {...props} /> },
  ACCOUNTING: { component: (props) => <FormNumberInput {...props} /> },
  RADIO: { component: FormRadio, options: true },
  DATE: { component: FormDatePicker },
  DATE_TIME: { component: FormDateTimePicker },
  TIME: { component: FormTimeInput },
  PASSWORD: { component: FormPassword },
  LOOKUP_USER: { component: FormUserSelect, clearable: true },
  LOOKUP_CARD: { component: CardSelect, clearable: true },
  COLOR: { component: FormColorPicker },
  IMAGE_URL: { component: FormInput },
  AGE: { component: FormAgePicker },
  // LOOKUP_ACTIVITY

  // not sure
  // FILE: { component: (props) => <FormInput type="file" {...props} /> },
  // IMAGE: { component: (props) => <FormInput type="file" {...props} /> },
  // HIDDEN: { component: (props) => <FormInput type="hidden" {...props} /> },
  // RANGE: { component: (props) => <FormInput type="range" {...props} /> },
  // SOCIAL: { component: (props) => <FormInput type="url" {...props} /> },
};
