import { PasswordInput, type PasswordInputProps } from '@mantine/core';
import { useController, useFormContext } from 'react-hook-form';
import { type IBaseFormInputProps } from './shared/FormInputTypes';
import { Label } from './shared/Label';
import { parseFormErrorMessage } from './shared/error-message.util';

export const FormPassword: React.FC<PasswordInputProps & IBaseFormInputProps> = ({
  name,
  label,
  required = false,
  description,
  ...props
}) => {
  const { control } = useFormContext();
  const {
    field: { value, ...rest },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const errorMessage = parseFormErrorMessage(error?.message);

  return (
    <PasswordInput
      id={name}
      value={value as string}
      label={label ? <Label label={label} description={description} required={required} /> : undefined}
      styles={{ label: { width: '100%' } }}
      error={errorMessage}
      {...rest}
      {...props}
    />
  );
};
