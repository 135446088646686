import { type DatePickerInputProps } from '@mantine/dates';
import { IconCake } from '@tabler/icons-react';
import { dateTimeService } from '~/utils/date-time.service';
import { type IBaseFormInputProps } from '../shared/FormInputTypes';
import { FormDatePicker } from './DatePicker';

export const FormAgePicker: React.FC<DatePickerInputProps & IBaseFormInputProps> = (props) => {
  return (
    <FormDatePicker
      {...props}
      valueFormatter={({ date }) => dateTimeService.toAge(date)}
      rightSection={<IconCake size="1rem" />}
      defaultLevel="decade"
      minDate={new Date(1900, 0, 1)}
      maxDate={new Date()}
    />
  );
};
