import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { create } from 'zustand';
import { type ICreateTask } from '~/server/services/activity/types';
import { api } from '~/trpc';
import { useActivityDelete } from '../../activity-delete.hook';

type UpsertTask = {
  cardId: string | null;
  task: ICreateTask | null;
  opened: boolean;
  open: ({ cardId, task }: { cardId?: string; task?: ICreateTask }) => void;
  close: () => void;
};

export const useUpsertTaskStore = create<UpsertTask>((set) => ({
  cardId: null,
  task: null,
  opened: false,
  open: ({ cardId, task }) => set({ opened: true, task, cardId }),
  close: () => set({ opened: false, task: null, cardId: '' }),
}));

export const useUpsertTask = () => {
  const { t } = useTranslation('activitiesComponent');
  const { opened, cardId, task, close } = useUpsertTaskStore();
  const utils = api.useUtils();

  const isNew = !task?.id;

  const { deleteActivity } = useActivityDelete({
    title: t('Task deleted'),
    message: t('Task deleted successfully'),
  });

  const del = useCallback(() => {
    modals.openConfirmModal({
      title: <Text fw={500}>{t('Task delete')}</Text>,
      children: <Text size={'sm'}>{t('Are you sure you want to delete this task?')}</Text>,
      onConfirm() {
        if (!task?.id) return;
        deleteActivity({ id: task.id });
        close();
      },
    });
  }, [task, deleteActivity, close, t]);

  const { mutate: upsert, isPending: isLoading } = api.tasks.upsert.useMutation({
    onSuccess: async () => {
      notifications.show({
        title: isNew ? t('Task created') : t('Task updated'),
        message: isNew ? t('Task created successfully') : t('Task updated successfully'),
        color: 'green',
      });
      close();

      await utils.activities.search.invalidate();
      await utils.cards.search.invalidate({ columnVisibility: { lastActivity: true } });
    },
    onError: (error) => {
      notifications.show({
        title: t('Error'),
        message: error.message,
        color: 'red',
      });
    },
  });

  return {
    open,
    close,
    opened,
    cardId,
    task,
    del,
    upsert,
    isLoading,
  };
};
