import { useParams } from 'next/navigation';
import { useMemo } from 'react';
import { create } from 'zustand';
import { type ISearchCardsInput } from '~/server/api/routers/cards/schemas';
import { api } from '~/trpc';

export interface ICardNavigationState {
  slug?: string;
  url?: string;
  input?: ISearchCardsInput;
}
export const useCardsNavigationHistory = create<ICardNavigationState>(() => ({
  slug: undefined,
  url: undefined,
  input: undefined,
}));

export const useCardsTableNavigation = (currentCardId?: string) => {
  const { url, input } = useCardsNavigationHistory();
  const { slug } = useParams<{ slug?: string }>();
  const utils = api.useUtils();

  const lastCardsTableUrl = url ?? `/cards/${slug}`;

  const previousCard = useMemo(() => {
    const pages = utils.cards.search.getInfiniteData(input)?.pages;
    if (!pages) return;
    const allCards = pages.flatMap((page) => page.results);
    const currentCardIndex = allCards.findIndex((card) => card.id === currentCardId);
    if (currentCardIndex === -1) return null;
    const previousCard = allCards[currentCardIndex - 1];
    if (!previousCard) return null;
    return { card: previousCard, url: `/cards/${slug}/${previousCard.id}` };
  }, [slug, currentCardId, input, lastCardsTableUrl]);

  const nextCard = useMemo(() => {
    const pages = utils.cards.search.getInfiniteData(input)?.pages;
    if (!pages) return;
    const allCards = pages.flatMap((page) => page.results);
    const currentCardIndex = allCards.findLastIndex((card) => card.id === currentCardId);
    if (currentCardIndex === -1) return null;
    const nextCard = allCards[currentCardIndex + 1];
    if (!nextCard) return null;
    return { card: nextCard, url: `/cards/${slug}/${nextCard.id}` };
  }, [slug, currentCardId, input, lastCardsTableUrl]);

  return {
    lastCardsTableUrl,
    nextCard,
    previousCard,
  };
};
