import { Button, type ButtonProps } from '@mantine/core';
import NextLink from 'next/link';
import { useCallback } from 'react';
import { useCardsNavigationHistory } from '~/Components/Cards/CardsTable/cards-table-navigation.hook';

type LinkToCardFieldProps = ButtonProps & {
  value: string;
  cardId?: string;
  slug?: string;
};

export const LinkToCardField: React.FC<LinkToCardFieldProps> = ({
  cardId = '',
  value,
  slug = '',
  ...rest
}) => {
  const cardLink = `/cards/${slug}/${cardId}`;
  const handleClick = useCallback(() => {
    useCardsNavigationHistory.setState((prev) => ({
      ...prev,
      url: window.location.href.replace(window.location.origin, ''),
      slug,
    }));
  }, [slug]);
  return (
    <Button
      variant="subtle"
      size="compact-sm"
      component={NextLink}
      href={cardLink}
      onClick={handleClick}
      {...rest}
    >
      {value}
    </Button>
  );
};
