import { type TextInputProps } from '@mantine/core';
import { IconLink } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AppLinkIcon } from '~/Components/Shared/Icon/AppLinkIcon';
import { zodUtils } from '~/utils/zod-utils';
import { FormInput } from '../Input/Input';
import { type IBaseFormInputProps } from '../shared/FormInputTypes';
type FormInputProps = TextInputProps & IBaseFormInputProps;

export const FormUrlInput: React.FC<FormInputProps> = ({ name, ...props }) => {
  const { t } = useTranslation('common');

  const { control } = useFormContext();
  const url = useWatch({ control, name });

  const sideSection = useMemo(() => {
    if (typeof url === 'string' && zodUtils.urlSchema.safeParse(url).success) {
      return (
        <AppLinkIcon
          href={zodUtils.urlSchema.safeParse(url).data!}
          target="_blank"
          Icon={IconLink}
          tooltip={t('Open in new tab')}
          height="60%"
          width="60%"
        />
      );
    }
    return (
      <AppLinkIcon
        href="#"
        Icon={IconLink}
        tooltip={t('Invalid URL')}
        disabled
        height="60%"
        width="60%"
      />
    );
  }, [url, t]);

  return <FormInput name={name} leftSection={sideSection} type={'url'} {...props} dir="ltr" />;
};
