import { z } from 'zod';
import { textTypeFields } from '~/server/services/card-type/types';
import { type FieldProps } from '~/server/services/templates/fields';
import { zodUtils } from '~/utils/zod-utils';
import { type Field, type FieldType } from '~db/types';

const getKey = (field: Pick<FieldProps, 'preDefinedColumn' | 'system' | 'relatedCardTypeId'>) => {
  if (field.relatedCardTypeId) return `values.${field.system}`;
  return field.preDefinedColumn ? field.system : `customFields.${field.system}`;
};

const mapFieldTypeToZodType: Partial<Record<FieldType, z.ZodTypeAny>> = {
  TEXT: z.string(),
  NUMBER: z.number(),
  DATE: z.coerce.date(),
  CHECKBOX: z.boolean(),
  SINGLE_SELECT: z.string(),
  DATE_TIME: z.coerce.date(),
  TIME: z.string().regex(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/),
  MULTI_SELECT: z.array(z.string()),
  EMAIL: z.string().email(),
  PHONE: z.string(),
  AGE: z.coerce.date(),
  LOOKUP_USER: z.string(),
  LOOKUP_CARD: z.string(),
  URL: zodUtils.urlSchema,
  TEXTAREA: z.string(),
  COLOR: z.string(),
  CURRENCY: z.number(),
  ACCOUNTING: z.number(),
  RADIO: z.string(),
};

const emptyStringToUndefined = z.literal('').transform(() => undefined);

export function addOptional<T extends z.ZodTypeAny>(schema: T, type: FieldType) {
  if (textTypeFields.includes(type)) {
    return schema.optional().nullable().or(emptyStringToUndefined);
  }
  return schema.optional().nullable();
}

const addRequired = (zodType: z.ZodTypeAny, type: FieldType) => {
  if (textTypeFields.includes(type)) {
    return (zodType as z.ZodString).min(1);
  }
  if (type === 'NUMBER') {
    return (zodType as z.ZodNumber).min(1);
  }

  if (type === 'MULTI_SELECT') {
    return (zodType as z.ZodArray<z.ZodTypeAny>).min(1);
  }
  return zodType;
};

type ZodRecord = Record<string, z.ZodTypeAny>;

function getZodType(field: Field) {
  let zodType = mapFieldTypeToZodType[field.type];
  if (!zodType) return null;
  if (field.required) {
    zodType = addRequired(zodType, field.type);
  } else {
    zodType = addOptional(zodType, field.type);
  }
  return zodType;
}

const createValidationSchema = (fields: Field[] = []): z.ZodObject<z.ZodRawShape> => {
  if (!fields) return z.object({});

  const customFieldsSchema = z
    .object({
      ...fields.reduce<ZodRecord>((acc, field) => {
        if (field.preDefinedColumn) return acc;
        if (field.relatedCardTypeId) return acc;
        const zodType = getZodType(field);
        if (!zodType) return acc;
        acc[field.system] = zodType;
        return acc;
      }, {}),
    })
    .optional()
    .nullable();

  return z.object({
    id: z.string().optional().nullable(),
    relatedCardIds: z.array(z.string()).optional().nullable(),
    customFields: customFieldsSchema,
    ...fields.reduce<ZodRecord>((acc, field) => {
      if (!field.preDefinedColumn && !field.relatedCardTypeId) return acc;
      const zodType = getZodType(field);
      if (!zodType) return acc;
      acc[field.system] = zodType;
      return acc;
    }, {}),
  });
};

export const cardFieldsUtils = {
  getKey,
  createValidationSchema,
};
