import { Group, Text, Tooltip } from '@mantine/core';
import { IconCirclesRelation } from '@tabler/icons-react';
import { type ColumnOrderState, type VisibilityState } from '@tanstack/table-core';
import { type TFunction } from 'i18next';
import { type MRT_ColumnDef } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { LeanActivityDisplay } from '~/Components/Card/Activities/Shared/LeanActivityDisplay';
import {
  type CardColumn,
  type ICardsTableViewSettingsColumns,
  type ICardsTableViewState,
} from '~/Components/Cards/types';
import { mapFieldTypeToFormComponent } from '~/Components/Settings/CardTypes/Fields/FieldsFormDefinitions';
import { FormFieldDisplay } from '~/Components/Settings/CardTypes/Fields/FieldsFormDisplay';
import { LinkToCardField } from '~/Components/Settings/CardTypes/Fields/LinkToCardField';
import { cardFieldsUtils } from '~/Components/Settings/CardTypes/Fields/fields.utils';
import { AppIcon } from '~/Components/Shared/Icon/AppIcon';
import { type PlainCardType } from '~/server/services/card-type/types';
import { appTableCellUtils } from '~/ui/table/table-cell.hook';
import { utils } from '~/utils/basic';
import { type Activity, type Card, CardType, type CardsTableView, type Field } from '~db/types';
import { cardsTableViewUtils } from './cards-table-view.utils';

/* convert view settings to columns config - save to db */
const generateColumnsConfig = <T extends Record<string, unknown>>({
  columnVisibility,
  columnOrder,
  columns,
}: {
  columnVisibility: VisibilityState;
  columnOrder: ColumnOrderState;
  columns: MRT_ColumnDef<T>[];
}): ICardsTableViewSettingsColumns => {
  return columns.reduce<ICardsTableViewSettingsColumns>(
    (acc: ICardsTableViewSettingsColumns, { id }) => {
      if (!id) return acc;
      acc[id] = {
        display: columnVisibility[id] ?? false,
        order: columnOrder.findIndex((val) => val === id) ?? 0,
      };

      return acc;
    },
    {},
  );
};

const getCell: (params: {
  field: Field;
  cardType: CardType;
}) => CardColumn['Cell'] = ({ field, cardType }) =>
  function Cell({ cell, row }) {
    if (field.system === 'name' && !cardType?.settings?.isHidden) {
      const value = cell.getValue();

      return (
        <LinkToCardField
          data-system={field?.system}
          value={value as string}
          cardId={row.original.id}
          slug={cardType.slug}
        />
      );
    }

    return appTableCellUtils.getCell<Card>({ cell, type: field.type, field });
  };

const getBaseColumns = (cardType: PlainCardType): CardColumn[] => {
  return [
    ...cardType.fields.map((field): CardColumn => {
      const accessorKey = cardFieldsUtils.getKey(field) as CardColumn['accessorKey'];
      return {
        accessorKey,
        header: field.label,
        id: field.system,
        meta: { field },
        ...appTableCellUtils.getCellOptions<Card>(field.type),
        ...(field.system === 'name' ? { enableHiding: false } : {}),
        Cell: getCell({ field, cardType }),
        Edit({ cell }) {
          if (!mapFieldTypeToFormComponent[field.type]?.component) {
            return appTableCellUtils.getCell<Card>({ cell, type: field.type, field });
          }

          return <FormFieldDisplay field={field} label={false} />;
        },
      };
    }),
  ];
};

const getRelatedCell: (params: { field: Field; slug: string }) => CardColumn['Cell'] = ({
  field,
  slug,
}) =>
  function Cell({ cell, row }) {
    if (field.system === 'name') {
      const value = cell.getValue();
      const relatedCardId = row.original?.relatedCard?.id;
      if (!relatedCardId) return appTableCellUtils.getCell<Card>({ cell, type: field.type, field });
      return (
        <LinkToCardField
          data-system={field?.system}
          value={value as string}
          cardId={relatedCardId}
          slug={slug}
          c="blue"
          color="blue"
        />
      );
    }

    return appTableCellUtils.getCell<Card>({ cell, type: field.type, field });
  };

const getRelatedColumns = (relatedCardTypes: PlainCardType[]) => {
  return relatedCardTypes.flatMap((c) =>
    c.fields.map<CardColumn>((field) => ({
      ...appTableCellUtils.getCellOptions<Card>(field.type),
      accessorFn(row) {
        const accessorKey = cardFieldsUtils.getKey(field) as CardColumn['accessorKey'];
        const shouldShow = c.id === row.relatedCard?.cardTypeId;
        if (!shouldShow) return null;
        return utils.get(row, `relatedCard.${accessorKey}`);
      },
      enableSorting: false,
      meta: { field },
      enableColumnFilter: false,
      header: `${field.label} - ${c.name}`,
      id: `${c.slug}.${field.system}`,
      Cell: getRelatedCell({ field, slug: c.slug }),
      Edit: ({ cell }) => appTableCellUtils.getCell<Card>({ cell, type: field.type, field }),
    })),
  );
};

const getRelatedValuesColumns = (relatedFields: Field[], allowEditRelatedValues: boolean) => {
  return relatedFields.map<CardColumn>((field) => ({
    accessorKey: `values.${field.system}`,
    ...appTableCellUtils.getCellOptions<Card>(field.type),
    enableSorting: false,
    enableColumnFilter: false,
    header: field.label,
    size: 120,
    id: `values.${field.system}`,
    meta: { field },
    Cell: ({ cell }) => appTableCellUtils.getCell<Card>({ cell, type: field.type, field }),
    Header() {
      const { t } = useTranslation('cardsPage');
      return (
        <Tooltip label={t('Related value')} position="top" withArrow>
          <Group gap={0}>
            <Text fs="italic" px={2}>
              {field.label}
            </Text>
            <AppIcon color="gray" stroke={1} Icon={IconCirclesRelation} size="1.4rem" />
          </Group>
        </Tooltip>
      );
    },
    Edit({ cell }) {
      if (!allowEditRelatedValues) {
        return appTableCellUtils.getCell<Card>({ cell, type: field.type, field });
      }
      if (!mapFieldTypeToFormComponent[field.type]?.component) {
        return appTableCellUtils.getCell<Card>({ cell, type: field.type, field });
      }

      return <FormFieldDisplay field={field} label={false} />;
    },
  }));
};

const getDefaultDisplay = (id: string, display?: boolean): boolean => {
  if (id.startsWith('values.')) return display ?? true;
  return display ?? false;
};

const LastActivityCell: CardColumn['Edit'] = ({ cell, row }) => {
  const activities = cell.getValue() as Activity[];
  const activity = activities?.[0];

  if (!activity) return null;
  return <LeanActivityDisplay activity={activity} card={row.original} />;
};

const getLastActivityColumns = (t: TFunction<'cardsPage'>): CardColumn[] => {
  return [
    {
      accessorKey: 'activities',
      enableSorting: false,
      enableColumnFilter: false,
      header: t('Last activity'),
      id: 'lastActivity',
      size: 300,
      Cell: LastActivityCell,
      Edit: LastActivityCell,
    },
  ];
};

/* convert view settings to columns ui config - load from db */
const getViewsColumns = ({
  cardViews,
  relatedCardTypes = [],
  relatedFields = [],
  showLastActivity = false,
  allowEditRelatedValues = false,
  cardType,
  t,
}: {
  cardViews: CardsTableView[];
  relatedCardTypes?: PlainCardType[];
  relatedFields?: Field[];
  allowEditRelatedValues?: boolean;
  showLastActivity?: boolean;
  cardType: PlainCardType;
  t: TFunction<'cardsPage'>;
}): ICardsTableViewState[] => {
  if (!cardViews.length) return [];
  const baseColumns: CardColumn[] = [
    ...getBaseColumns(cardType),
    ...getRelatedColumns(relatedCardTypes),
    ...(showLastActivity ? getLastActivityColumns(t) : []),
    ...getRelatedValuesColumns(relatedFields, allowEditRelatedValues),
  ];

  return cardViews.map((tableView): ICardsTableViewState => {
    const viewSettings = tableView.settings;
    const sortedColumns = cardsTableViewUtils.sortColumnsByOrder(baseColumns, viewSettings);
    const columnVisibility = sortedColumns.reduce<VisibilityState>((acc, { id }) => {
      if (!id) return acc;
      const display = id === 'name' ? true : viewSettings.columns?.[id]?.display;
      acc[id] = getDefaultDisplay(id, display);
      return acc;
    }, {});

    return {
      id: tableView.id,
      title: tableView.title,
      columns: sortedColumns,
      columnVisibility,
      sort: tableView?.settings?.sort,
      globalFilter: tableView.settings?.globalFilter,
      order: tableView.order,
      slug: tableView.slug,
      kanban: tableView.settings?.kanban,
    };
  });
};

export const cardsTableViewsService = {
  generateColumnsConfig,
  getViewsColumns,
};
