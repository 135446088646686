import {
  ActionIcon,
  type ActionIconProps,
  type ElementProps,
  Tooltip,
  type TooltipProps,
} from '@mantine/core';
import NextLink from 'next/link';
import { forwardRef } from 'react';
import { type TablerIconType } from './types';

export interface AppActionIconProps extends ActionIconProps, ElementProps<'a', keyof ActionIconProps> {
  Icon: TablerIconType;
  href: string;
  tooltip: string;
  position?: TooltipProps['position'];
  openDelay?: TooltipProps['openDelay'];
  height?: string;
  width?: string;
}

export const AppLinkIcon = forwardRef<HTMLAnchorElement, AppActionIconProps>(
  (
    {
      tooltip,
      position = 'top',
      Icon,
      color,
      openDelay = 200,
      href,
      height = '70%',
      width = '70%',
      ...props
    },
    ref,
  ) => {
    return (
      <Tooltip label={tooltip} position={position} openDelay={openDelay}>
        <ActionIcon component={NextLink} color={color} variant="subtle" {...props} ref={ref} href={href}>
          <Icon height={height} width={width} />
        </ActionIcon>
      </Tooltip>
    );
  },
);

AppLinkIcon.displayName = 'AppActionIcon';
