import { type DefaultMantineColor } from '@mantine/core';
import { type Activity } from '~db/types';

export const mapActivityToColor: Record<Activity['type'] | 'AUDIT', DefaultMantineColor> = {
  MEETING: 'violet',
  TASK: 'blue',
  CALL: 'gray',
  FACEBOOK: 'gray',
  INSTAGRAM: 'gray',
  LINKEDIN: 'gray',
  MAIL: 'gray',
  SMS: 'gray',
  TWITTER: 'gray',
  WHATSAPP: 'gray',
  COMMENT: 'dark',
  AUDIT: 'indigo',
};
