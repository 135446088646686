import { useDebouncedValue } from '@mantine/hooks';
import { IconCards } from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCard } from '~/Components/Card/card.hook';
import { type IFieldSetting } from '~/server/services/card-type/field.schema';
import { api } from '../../../trpc';
import { useCardTypes } from '../../Cards/card-types.hook';
import { FormSelect } from '../Select/Select';
import { FormSelectProps } from '../Select/types';

export const CardSelect: React.FC<Omit<FormSelectProps, 'data'> & { settings: IFieldSetting }> = ({
  name,
  ...props
}) => {
  const { t } = useTranslation('fieldsComponent');
  const { cardTypes, isLoadingCardTypes } = useCardTypes();
  const [query, setQuery] = useState('');
  const [debouncedValue] = useDebouncedValue(query, 200);
  const selectedCardType = useMemo(() => {
    const settings = props.settings;
    if (!cardTypes || !settings) return;
    if ('lookupId' in settings === false) return;
    const selected = cardTypes.find((c) => c.id === settings.lookupId);
    return selected;
  }, [cardTypes, props.settings]);
  const form = useFormContext();
  const id = form.watch(name) as string;
  const { card, isLoadingCard } = useCard(id);
  const [isInteracted, setIsInteracted] = useState(false);
  const enabled = isInteracted;

  const { data: cards = [], isFetching } = api.cards.freeSearch.useQuery(
    { query: debouncedValue, cardTypeIds: selectedCardType?.id ? [selectedCardType?.id] : [], id },
    { enabled },
  );

  const data = useMemo(() => {
    const results = cards.map((c) => ({ value: c.id, label: c.name })) ?? [];
    if (card && !cards.find((c) => c.id === card.id)) results.push({ value: card.id, label: card.name });
    return results;
  }, [cards, card]);

  return (
    <FormSelect
      searchable
      onOpen={() => setIsInteracted(true)}
      onSearchChange={(value) => setQuery(value)}
      loading={isFetching ?? isLoadingCardTypes ?? isLoadingCard}
      placeholder={t('Select {{cardType}}...', { cardType: selectedCardType?.name })}
      name={name}
      icon={<IconCards size="1rem" />}
      {...props}
      data={data}
    />
  );
};
