import { Grid, type SelectProps, Text } from '@mantine/core';
import { type Field } from '../../../../../db/types';
import { type FieldProps } from '../../../../server/services/templates/fields';
import { mapFieldTypeToFormComponent } from './FieldsFormDefinitions';
import { cardFieldsUtils } from './fields.utils';

export const FormFieldsDisplay = ({ fields, wide = false }: { fields: Field[]; wide?: boolean }) => {
  return (
    <Grid gutter="sm">
      {fields.map((field) => {
        const { component: Component, span = 6 } = mapFieldTypeToFormComponent[field.type] ?? {};
        if (!Component) {
          return <Text key={field.id}>Unknown field type: {field.type}</Text>;
        }
        return (
          <Grid.Col span={{ xl: wide ? 6 : 12, xxl: span }} key={field.id}>
            <FormFieldDisplay field={field} key={field.id} />
          </Grid.Col>
        );
      })}
    </Grid>
  );
};

export const FormFieldDisplay = ({
  field,
  name,
  label = true,
  variant,
  size,
  hideError,
}: {
  field: FieldProps;
  name?: string;
  label?: boolean;
  variant?: SelectProps['variant'];
  size?: SelectProps['size'];
  hideError?: boolean;
}) => {
  const definition = mapFieldTypeToFormComponent[field.type];
  const fieldName = name ?? cardFieldsUtils.getKey(field);
  const { component: Component, clearable } = definition ?? {};
  if (!Component) {
    return <Text>Not implemented: {field.type}</Text>;
  }
  return (
    <Component
      label={label ? field.label : undefined}
      data={field.options}
      required={field.required}
      description={field.description}
      settings={field.settings}
      disabled={field.internal}
      name={fieldName}
      clearable={clearable}
      variant={variant}
      size={size}
      hideError={hideError}
    />
  );
};
