import { createInsertSchema } from 'drizzle-zod';
import { z } from 'zod';
import { createFieldsToOmit } from '~/server/api/schemas';
import { activitiesTable, communicationTypes } from '~db/schema';
import { allActivityTypes } from '~db/schema/activities';
import { type Activity } from '~db/types';

export const activitySettingsSchema = z
  .object({
    smsReminder: z.boolean().default(false).optional(),
    emailReminder: z.boolean().default(false).optional(),
  })
  .optional()
  .nullable();

// Activities
export const activitySchema = createInsertSchema(activitiesTable, {
  updatedAt: z.date().default(() => new Date()),
  guests: z.array(z.string()).default([]).optional(),
  attachmentFilenames: z.array(z.string()).default([]).optional(),
  settings: activitySettingsSchema,
}).omit(createFieldsToOmit);

export const communicationSchema = activitySchema.extend({
  type: z.enum(communicationTypes),
  inbound: z.boolean(),
});
export type ICreateCommunication = z.infer<typeof communicationSchema>;

export const mailSchema = communicationSchema.extend({
  type: z.literal('MAIL'),
  title: z.string().min(1),
  description: z.string().min(1),
});
export type ICreateMail = z.infer<typeof mailSchema>;

export const commentSchema = activitySchema.extend({
  type: z.literal('COMMENT'),
  description: z.string().min(1).trim(),
  inbound: z.boolean().default(false),
});
export type ICreateComment = z.infer<typeof commentSchema>;

export const meetingSchema = activitySchema.extend({
  type: z.literal('MEETING'),
  endDate: z.date(),
  title: z.string().min(1),
  description: z.string().min(1),
});
export type ICreateMeeting = z.infer<typeof meetingSchema>;

export const taskSchema = activitySchema.extend({
  type: z.literal('TASK'),
  assignedTo: z.string().min(1),
  done: z.boolean().nullable().default(false).optional(),
  description: z.string().min(1),
});
export type ICreateTask = z.infer<typeof taskSchema>;

export const upsertActivitySchema = z.union([communicationSchema, mailSchema, commentSchema]);
export type ICreateActivity = z.infer<typeof activitySchema>;

export type Task = Activity & z.output<typeof taskSchema>;
export type Meeting = Activity & z.output<typeof meetingSchema>;
export type Comment = Activity & z.output<typeof commentSchema>;
export type Mail = Activity & z.output<typeof mailSchema>;
export type Communication = z.output<typeof communicationSchema>;
export const isCommunication = (
  activity?: Activity | ICreateCommunication | null,
): activity is Communication => activity?.type !== 'TASK' && activity?.type !== 'MEETING';

export const isMeeting = (activity?: Activity | ICreateMeeting | null): activity is Meeting =>
  activity?.type === 'MEETING';

export const isTask = (activity: Activity | ICreateTask | null | undefined): activity is Task =>
  activity?.type === 'TASK';

export type ReminderType = 'scheduled' | 'changed' | 'canceled' | 'reminder';

export type IActivitySettings = z.infer<typeof activitySettingsSchema>;

export const activityTypeSchema = z.enum(allActivityTypes);
