import React from 'react';
import '@mantine/dates/styles.css';
import { DatePickerInput, type DatePickerInputProps } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import { useController, useFormContext } from 'react-hook-form';
import { useLocale } from '~/ui/hooks/locale.hook';
import { type IBaseFormInputProps } from '../shared/FormInputTypes';
import { Label } from '../shared/Label';
import { parseFormErrorMessage } from '../shared/error-message.util';
import { dateComponentUtils } from './date.component.utils';

const defaultRightSection = <IconCalendar size="1rem" />;

export const FormDatePicker: React.FC<DatePickerInputProps & IBaseFormInputProps> = ({
  name,
  label,
  description,
  required = false,
  type = 'default',
  hideError,
  miw = '6rem',
  rightSection = defaultRightSection,
  ...props
}) => {
  const { control } = useFormContext();
  const {
    field: { value, ...rest },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const { align } = useLocale();
  const errorMessage = parseFormErrorMessage(error?.message);
  const dateValue: Date = typeof value === 'string' ? new Date(value) : value;

  return (
    <DatePickerInput
      id={name}
      value={dateValue}
      type={type}
      miw={miw}
      renderDay={dateComponentUtils.dayRenderer}
      styles={{ input: { textAlign: align }, label: { width: '100%' } }}
      label={label ? <Label label={label} description={description} required={required} /> : undefined}
      error={hideError ? Boolean(errorMessage) : errorMessage}
      rightSection={rightSection}
      {...rest}
      {...props}
      onChange={(date) => {
        rest.onChange(date);
      }}
    />
  );
};
