import { ThemeIcon, type ThemeIconProps } from '@mantine/core';
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconBrandWhatsapp,
  IconCheckbox,
  IconEdit,
  IconMailFilled,
  IconMessageCircle2Filled,
  IconNote,
  IconPhoneCall,
} from '@tabler/icons-react';
import { IconCalendarFilled } from '@tabler/icons-react';
import { type Activity } from '~db/types';
import { mapActivityToColor } from '../colors';

export const activitiesIcons: Record<Activity['type'] | 'AUDIT', JSX.Element> = {
  MAIL: <IconMailFilled size={'1rem'} />,
  CALL: <IconPhoneCall size={'1rem'} />,
  SMS: <IconMessageCircle2Filled size={'1rem'} />,
  WHATSAPP: <IconBrandWhatsapp size={'1rem'} />,
  FACEBOOK: <IconBrandFacebook size={'1rem'} />,
  INSTAGRAM: <IconBrandInstagram size={'1rem'} />,
  TWITTER: <IconBrandTwitter size={'1rem'} />,
  LINKEDIN: <IconBrandLinkedin size={'1rem'} />,
  MEETING: <IconCalendarFilled size={'1rem'} />,
  TASK: <IconCheckbox size={'1rem'} />,
  COMMENT: <IconNote size={'1rem'} />,
  AUDIT: <IconEdit size={'1rem'} />,
};

type IActivityIconProps = ThemeIconProps & {
  type: Activity['type'] | 'AUDIT';
};

export const ActivityIcon: React.FC<IActivityIconProps> = ({ type, ...props }) => {
  return (
    <ThemeIcon size={22} variant="transparent" color={mapActivityToColor[type]} {...props}>
      {activitiesIcons[type]}
    </ThemeIcon>
  );
};
