import { Skeleton } from '@mantine/core';
import { useCard } from '~/Components/Card/card.hook';
import { useCardTypes } from '~/Components/Cards/card-types.hook';
import { AppLink } from '~/Components/Shared/AppLink';

export const LookupCardDisplay: React.FC<{
  value: unknown;
}> = ({ value }) => {
  const { card } = useCard(value as string);
  const { cardTypes } = useCardTypes();
  const selectedCardType = cardTypes?.find((c) => c.id === card?.cardTypeId);
  if (typeof value !== 'string') return null;
  return (
    <AppLink href={`/cards/${selectedCardType?.slug}/${value}`}>
      {card ? card.name : <Skeleton width={100} height={20} />}
    </AppLink>
  );
};
