import { ActionIcon } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';
import { useLocale } from '~/ui/hooks/locale.hook';
import { testIdUtils } from '~/utils/test-id.service';

type IEditButtonProps = {
  handleClick?: () => void;
  show: boolean;
  center?: boolean;
  isLoading?: boolean;
};

export const FloatingEditButton: React.FC<IEditButtonProps> = ({
  handleClick,
  show,
  center,
  isLoading,
}) => {
  const { alignContent } = useLocale();

  return (
    <ActionIcon
      variant="light"
      onClick={() => handleClick?.()}
      pos="absolute"
      opacity={show ? 1 : 0}
      style={{
        transition: 'opacity 200ms ease',
        ...(center && { transform: `translate(0, 50%)`, top: 0 }),
        [alignContent]: '1rem',
      }}
      color="gray"
      radius="xl"
      loading={isLoading}
      {...testIdUtils.prop('floating-edit-button')}
    >
      <IconPencil size="1rem" />
    </ActionIcon>
  );
};
