import { useAuth } from '@clerk/nextjs';
import { api } from '../../trpc';

export const useCardType = (slug: string) => {
  const { isSignedIn, isLoaded, orgId } = useAuth();
  const {
    data: cardType,
    isLoading: isLoadingCardType,
    isFetching: isFetchingCardType,
    error: cardTypeError,
  } = api.cardTypes.get.useQuery(
    { slug },
    {
      enabled: Boolean(isSignedIn) && isLoaded && Boolean(orgId) && Boolean(slug),
      staleTime: 2 * 60 * 60 * 1000,
      gcTime: 5 * 60 * 60 * 1000,
    },
  );

  return {
    cardType,
    isLoadingCardType,
    isFetchingCardType,
    cardTypeError,
  };
};
