const HEADER = 60;
const HEADER_PADDING = 12;
const REGULAR_TABS = 36;

export const appHeights = {
  HEADER,
  APP_MINUS_HEADER: `calc(100vh - ${HEADER + HEADER_PADDING * 2}px)`,
  APP_MINUS_HEADER_AND_TABS: `calc(100vh - ${HEADER + REGULAR_TABS * 2 + 5}px)`,
  APP_TABLE: `calc(100vh - 180px)`,
  APP_KANBAN_COLUMN: `calc(100vh - 335px)`,
  APP_KANBAN: `calc(100vh - 265px)`,
  LARGE_MODAL_CONTENT: `calc(100vh - 250px)`,
  APP_WITH_SUB_HEADER: `calc(100vh - 180px)`,
};
