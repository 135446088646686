import { PlainCardType } from '~/server/services/card-type/types';
import { api } from '~/trpc';

export const useRelatedFields = (cardType?: PlainCardType) => {
  const {
    data: allRelatedFields = [],
    isLoading,
    ...rest
  } = api.fields.getRelated.useQuery(
    { mainCardTypeId: cardType?.id ?? '' },
    {
      enabled: Boolean(cardType?.id),
      gcTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 10,
    },
  );

  return {
    allRelatedFields,
    isLoading,
    ...rest,
  };
};
