import { createInsertSchema } from 'drizzle-zod';
import { z } from 'zod';
import { createFieldsToOmit, fieldSchema } from '~/server/api/schemas';
import {
  type ColumnVisibility,
  type ISearchRequest,
  type ISearchResponse,
} from '~/server/services/types';
import { zodUtils } from '~/utils/zod-utils';
import { cardTypesTable } from '~db/schema';
import { type Card, type CardLayout, type CardType, type CardsTableView, type Field } from '~db/types';
import { CardWithRelation } from '../card/types';
import { cardTypeSettings } from './settings.types';

export const cardTypeSchema = createInsertSchema(cardTypesTable, {
  updatedAt: z.date().default(() => new Date()),
  slug: zodUtils.slugSchema,
  order: zodUtils.numberSchema,
  settings: cardTypeSettings.optional(),
}).omit(createFieldsToOmit);

export const cardTypeWithFieldsSchema = cardTypeSchema.extend({
  fields: z.array(fieldSchema).optional(),
});

export type ICreateCardType = z.output<typeof cardTypeSchema>;
export type ICreateCardTypeWithFields = z.infer<typeof cardTypeWithFieldsSchema>;

export type ISearchCardsServiceInput = ISearchRequest<Card, Record<string, unknown>> & {
  cardTypeId: string;
  userId: string;
  globalFilter?: string;
  columnVisibility?: ColumnVisibility;
};

export type ISearchCardsServiceResponse = ISearchResponse<CardWithRelation>;

export type PlainCardType = CardType & {
  fields: Field[];
  cardLayouts: CardLayout[];
  cardsTableViews: Pick<CardsTableView, 'id' | 'title' | 'slug' | 'order'>[];
};

export type ExtendedCardType = CardType & {
  fields: Field[];
  cardLayouts: (CardLayout & { cardsTableViews: CardsTableView[] })[];
  cardsTableViews: CardsTableView[];
};

export type CardLayoutWithViews = CardLayout & { cardsTableViews: CardsTableView[] };

export const textTypeFields = ['TEXT', 'EMAIL', 'PHONE', 'URL', 'TEXTAREA'];

export const identifierFieldTypes = ['TEXT', 'EMAIL', 'PHONE', 'URL', 'TEXTAREA', 'IMAGE_URL', 'NUMBER'];
