import JsonURL from '@jsonurl/jsonurl';
import { type MRT_SortingState, type MRT_TableOptions } from 'mantine-react-table';
import {
  type ESortOrder,
  type IFilter,
  type ISearchResponse,
  type ISingleFilter,
  type ISort,
} from '~/server/services/types';
import { type IGetDefaultConfigInput } from '~/ui/table/types';
import { appHeights } from '../app-constants';
import { type TQueryParams } from '../hooks/app.router.hook';
import './styles.css';
import { type SortingState } from '@tanstack/table-core';
import { type ICardsTableViewSettingsColumns } from '~/Components/Cards/types';
import { type FilterField } from '~/Components/Form/MultiFilters/MultiFilters';
import { utils } from '~/utils/basic';
import { filtersUtils } from '~/utils/filters.utils';
import { fieldTypes } from '~db/schema/consts/fields';

export const getNextPageParam = <T>(lastPage: ISearchResponse<T>): number | undefined => {
  if (lastPage?.results.length === 0) return;
  if (lastPage.total < lastPage.nextCursor) return;
  return lastPage?.nextCursor;
};

export const getDefaultConfig = <T extends Record<string, unknown>>({
  infiniteScroll,
  onGlobalFilterChange,
  setSorting,
  rowVirtualizerInstanceRef,
  tableContainerRef,
  fetchMoreOnBottomReached,
  ...rest
}: IGetDefaultConfigInput & Partial<MRT_TableOptions<T>>): Partial<MRT_TableOptions<T>> => {
  const config: Partial<MRT_TableOptions<T>> = {
    enableEditing: false,
    enableColumnResizing: true,
    enableColumnDragging: false,
    enableRowNumbers: false,
    enableRowSelection: false,
    enableFullScreenToggle: false,
    enableColumnOrdering: true,
    enableStickyHeader: true,
    enableDensityToggle: false,
    layoutMode: 'grid',
    enableRowVirtualization: true, //optional, but recommended if it is likely going to be more than 100 rows
    ...(infiniteScroll
      ? {
          enablePagination: false,
          manualFiltering: true,
          manualSorting: true,
          rowVirtualizerInstanceRef, //get access to the virtualizer instance
          rowVirtualizerProps: { overscan: 20 }, //optionally customize the row virtualizer
          columnVirtualizerProps: { overscan: 2 }, //optionally customize the column virtualizer
          enableColumnFilters: false,
          onGlobalFilterChange,
          onSortingChange: setSorting,
          mantineTableContainerProps: {
            ref: tableContainerRef, //get access to the table container element
            style: { maxHeight: appHeights.APP_TABLE },
            onScroll: (
              event: React.UIEvent<HTMLDivElement, UIEvent>, //add an event listener to the table container element
            ) => fetchMoreOnBottomReached({ containerRefElement: event.target as HTMLDivElement }),
          },
          initialState: {
            density: 'xs',
          },
          rowVirtualizerOptions: { overscan: 20 }, //optionally customize the row virtualizer
        }
      : {
          initialState: {
            density: 'xs',
            pagination: { pageSize: 50, pageIndex: 0 },
          },
          paginationDisplayMode: 'pages',
        }),
    ...rest,
  };

  return config;
};

const extractSort = (params: TQueryParams): MRT_SortingState | null => {
  if (!params?.sort) return null;

  const sortUrl = encodeURIComponent(params.sort);
  const sort = JsonURL.parse(sortUrl, { AQF: true }) as Record<string, unknown>;

  return Object.entries(sort).map(([key, order]) => {
    return { id: key, desc: (order as ESortOrder) === 'DESC' };
  });
};

const formatSort = <T extends Record<string, unknown>>(sort?: ISort<T>[]): undefined | string => {
  if (!sort) return;

  const sortData = sort.reduce<Record<string, unknown>>((current, { field, order }) => {
    current[field] = order;
    return current;
  }, {});

  if (Object.keys(sortData).length) {
    return JsonURL.stringify(sortData, { AQF: true });
  }
};

const getFilterValueFormatted = (filterItem: ISingleFilter['value']): unknown => {
  if (Array.isArray(filterItem)) {
    const format = (filterItem as unknown[]).map((item) => {
      // update date format
      if (item instanceof Date) {
        return item.toISOString();
      }
      return item;
    });

    return format.filter((v) => v !== undefined)?.length ? format : null;
  }

  return filterItem;
};

const formatFilters = <T extends Record<string, unknown>>(filters?: IFilter<T>): undefined | string => {
  if (!filters) return;

  const queryData = Object.entries(filters).reduce<Record<string, unknown>>((current, [key, data]) => {
    if (data) {
      const { type, operator, value } = data;
      const isDefaultOperator = filtersUtils.defaultOperator[type] === operator;
      const newFilterItemValue = getFilterValueFormatted(value);
      current[key] = {
        type,
        ...(utils.isDefined(newFilterItemValue) ? { value: newFilterItemValue } : {}),
        ...(!isDefaultOperator ? { operator } : {}),
      };
    }

    return current;
  }, {});

  if (Object.keys(queryData).length) {
    return JsonURL.stringify(queryData, { AQF: true });
  }
};

const isSingleFilter = (filter: unknown): filter is ISingleFilter => {
  return (
    typeof filter === 'object' &&
    filter !== null &&
    'type' in filter &&
    fieldTypes.some((type) => type === filter.type)
  );
};

const extractFilters = <T extends Record<string, unknown>>(
  filtersValue?: string,
  fields?: FilterField[],
): IFilter<T> | null => {
  if (!filtersValue) return null;
  const filtersUrl = encodeURIComponent(filtersValue);
  const filters = JsonURL.parse(filtersUrl, { AQF: true }) as Record<string, unknown>;

  const parsedFilters = Object.entries(filters as IFilter<T>).reduce<Record<string, unknown>>(
    (current, [key, data]) => {
      const field = fields?.find((f) => f.system === key);
      if (isSingleFilter(data) && field) {
        const { operator, value } = data;
        const fieldType = filtersUtils.fieldTypeToFilter(field.type);
        current[key] = {
          type: fieldType,
          operator: operator ? operator : filtersUtils.defaultOperator[fieldType],
          ...(value ? { value } : {}),
        };
      } else {
        current[key] = data;
      }

      return current;
    },
    {},
  );

  return parsedFilters as IFilter<T>;
};

const formatColumns = (columns?: ICardsTableViewSettingsColumns): undefined | string => {
  if (!columns) return;

  const columnsData = Object.entries(columns)
    .filter(([, value]) => value.display)
    .sort(([, a], [, b]) => (a.order ?? 99) - (b.order ?? 99))
    .map(([key]) => key);

  if (columnsData.length) {
    return JsonURL.stringify(columnsData, { AQF: true });
  }
};

const extractColumns = (params: TQueryParams): string[] => {
  if (!params?.columns) return [];

  const columnsUrl = encodeURIComponent(params.columns);
  const urlColumns = JsonURL.parse(columnsUrl, { AQF: true }) as string[];
  return urlColumns;
};

const sortToState = <T extends Record<string, unknown>>(sort: ISort<T>[]): SortingState => {
  return sort.map((s) => ({ id: s.field, desc: s.order === 'DESC' }));
};

const stateToSort = <T extends Record<string, unknown>>(sort: MRT_SortingState): ISort<T>[] => {
  return sort.map((s) => ({ field: s.id, order: s.desc ? 'DESC' : 'ASC' }));
};

export const tableUtils = {
  formatSort,
  extractSort,
  formatFilters,
  extractFilters,
  formatColumns,
  extractColumns,
  sortToState,
  stateToSort,
};
