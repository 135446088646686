import { type TextInputProps } from '@mantine/core';
import { IconAt } from '@tabler/icons-react';
import { FormInput } from './Input/Input';
import { type IBaseFormInputProps } from './shared/FormInputTypes';

export const FormEmailInput: React.FC<TextInputProps & IBaseFormInputProps> = (props) => {
  return (
    <FormInput
      leftSection={<IconAt size="0.8rem" />}
      type="email"
      dir="ltr"
      placeholder="name@mail.com"
      {...props}
    />
  );
};
