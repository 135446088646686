import { Anchor, Flex, Stack, Text, useMantineTheme } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconEdit } from '@tabler/icons-react';
import { type CountryCode, formatNumber } from 'libphonenumber-js';
import React, { useMemo, useState } from 'react';
import { FlagImage, guessCountryByPartialPhoneNumber } from 'react-international-phone';
import { useColorBySchema } from '~/ui/hooks/mantine-color.hook';

const DEFAULT_COUNTRY = 'il';

const getDefaultCountryValue = (value: string) => {
  if (!value) {
    return DEFAULT_COUNTRY;
  }

  const { fullDialCodeMatch, country } = guessCountryByPartialPhoneNumber({
    phone: value,
  });

  return fullDialCodeMatch ? (country?.iso2 ?? DEFAULT_COUNTRY) : DEFAULT_COUNTRY;
};

const PhoneInputView: React.FC<{
  value: string;
  disableEdit?: boolean;
  error?: React.ReactNode;
  name?: string;
  border?: boolean;
  onEditClicked?: () => void;
}> = ({ value, error, name, onEditClicked, disableEdit, border }) => {
  const theme = useMantineTheme();
  const { hovered, ref } = useHover();

  const [countryCode] = useState<string>(getDefaultCountryValue(value));

  const cellValue = useMemo(() => {
    return value ? formatNumber(value, countryCode?.toUpperCase() as CountryCode, 'RFC3966') : value;
  }, [value, countryCode]);

  const bgColor = useColorBySchema('white', 'dark.6');
  const borderColor = useColorBySchema('gray.4', 'dark.4');

  return (
    <Stack gap="xs" id={name}>
      <Flex
        ref={ref}
        gap={5}
        align="center"
        bg={border ? bgColor : 'transparent'}
        h={36}
        px={5}
        style={{
          direction: 'ltr',
          border: border ? `1px solid ${borderColor}` : '',
          borderRadius: `${theme.radius.sm}`,
        }}
      >
        {value ? <FlagImage size={'1.8rem'} iso2={countryCode} style={{ padding: '0 2px' }} /> : null}
        <Anchor
          c="blue"
          size="sm"
          style={{
            flexGrow: 1,
          }}
          onClick={(ev) => ev.stopPropagation()}
          href={cellValue}
        >
          {value}
        </Anchor>

        {!disableEdit && (
          <IconEdit
            opacity={hovered ? 1 : 0}
            size={16}
            cursor="pointer"
            style={{ flexShrink: 0 }}
            onClick={onEditClicked}
          />
        )}
      </Flex>
      {error && (
        <Text size="sm" c="red">
          {error}
        </Text>
      )}
    </Stack>
  );
};

export default PhoneInputView;
