import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { create } from 'zustand';
import { type ICreateMeeting } from '~/server/services/activity/types';
import { api } from '~/trpc';
import { useActivityDelete } from '../../activity-delete.hook';

type UpsertMeeting = {
  cardId: string | null;
  meeting: ICreateMeeting | null;
  opened: boolean;
  open: ({ cardId, meeting }: { cardId?: string; meeting?: ICreateMeeting }) => void;
  close: () => void;
};

export const useUpsertMeetingStore = create<UpsertMeeting>((set) => ({
  cardId: null,
  meeting: null,
  opened: false,
  open: ({ cardId, meeting }) => set({ opened: true, meeting, cardId }),
  close: () => set({ opened: false, meeting: null, cardId: '' }),
}));

export const useUpsertMeeting = () => {
  const { t } = useTranslation('activitiesComponent');
  const { opened, cardId, meeting, close } = useUpsertMeetingStore();
  const utils = api.useUtils();

  const isNew = !meeting?.id;

  const { mutate: upsert, isPending: isLoading } = api.meetings.upsert.useMutation({
    onSuccess: async () => {
      notifications.show({
        title: isNew ? t('Meeting created') : t('Meeting updated'),
        message: isNew ? t('Meeting created successfully') : t('Meeting updated successfully'),
        color: 'green',
      });
      close();

      await utils.activities.search.invalidate();
      await utils.cards.search.invalidate({ columnVisibility: { lastActivity: true } });
      await utils.activities.autoComplete.invalidate();
    },
    onError: (error) => {
      notifications.show({
        title: t('Error'),
        message: error.message,
        color: 'red',
      });
    },
  });

  const { deleteActivity } = useActivityDelete({
    title: t('Meeting deleted'),
    message: t('Meeting deleted successfully'),
  });

  const handleDelete = useCallback(() => {
    modals.openConfirmModal({
      title: <Text fw={500}>{t('Meeting delete')}</Text>,
      children: <Text size={'sm'}>{t('Are you sure you want to delete this meeting?')}</Text>,
      onConfirm() {
        if (!meeting?.id) return;
        deleteActivity({ id: meeting.id });
        close();
      },
    });
  }, [meeting, deleteActivity, close, t]);

  return {
    close,
    opened,
    cardId,
    meeting,
    handleDelete,
    upsert,
    isLoading,
  };
};
