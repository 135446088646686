import { ActionIcon, Button, type ButtonProps, Group, Popover, Stack, Text } from '@mantine/core';
import '@mantine/dates/styles.css';
import { useClickOutside } from '@mantine/hooks';
import { IconX } from '@tabler/icons-react';
import { forwardRef, useCallback, useMemo, useState } from 'react';
import { testIdUtils } from '~/utils/test-id.service';
import { SuperDateRangePickerContainer } from './Container';
import { useDatePickerDisplayDate } from './display-date.hook';
import {
  type SuperDatePickerOnChange,
  type SuperDatePickerProps,
  type SuperDateRangeInitialValue,
  type SuperDateRangeValue,
} from './types';
import { superDateRangePickerUtils } from './utils';

const DisplayDate = forwardRef<HTMLButtonElement, SuperDatePickerProps>(
  ({ label, displayReset, setOpened, value, setValue, onChange, size, ...props }, ref) => {
    const { displayDate } = useDatePickerDisplayDate(value);

    const haveAnyValue = useMemo(() => {
      return Boolean(value && value.length > 0);
    }, [value]);

    return (
      <Group wrap="nowrap">
        <Stack gap={1}>
          {label && (
            <Text fz="xs" c="dimmed">
              {label}
            </Text>
          )}
          <Button
            ref={ref}
            onClick={(e) => {
              e.stopPropagation();
              setOpened?.((prev) => !prev);
            }}
            variant="outline"
            size={size}
            my={0}
            radius="sm"
            px={'xs'}
            {...props}
            {...testIdUtils.prop('super-date-range-picker')}
          >
            {displayDate}
          </Button>
        </Stack>
        {displayReset && haveAnyValue && (
          <ActionIcon
            size={'sm'}
            variant="subtle"
            onClick={() => {
              setValue(null);
              onChange(null);
            }}
          >
            <IconX size="0.9rem" />
          </ActionIcon>
        )}
      </Group>
    );
  },
);
DisplayDate.displayName = 'DisplayDate';

export const SuperDateRangePicker: React.FC<{
  onChange: SuperDatePickerOnChange;
  initialValue?: SuperDateRangeInitialValue;
  displayReset?: boolean;
  label?: string;
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
}> = ({
  onChange,
  initialValue,
  displayReset = true,
  label,
  variant = 'subtle',
  size = 'compact-sm',
}) => {
  const [opened, setOpened] = useState(false);
  const parsedInitialValues = useMemo(
    () => superDateRangePickerUtils.tryParseInitialValue(initialValue),
    [initialValue],
  );
  const [value, setValue] = useState<SuperDateRangeValue | undefined>(parsedInitialValues);
  const [button, setButton] = useState<HTMLButtonElement | null>(null);
  const [card, setCard] = useState<HTMLDivElement | null>(null);
  const ref = useClickOutside(() => setOpened(false), undefined, [button, card]);

  const handleOnChange = useCallback(
    (value: SuperDateRangeValue) => {
      setValue(value);
      onChange(value);

      setOpened(false);
    },
    [onChange],
  );

  return (
    <Popover opened={opened} position="bottom-start" withinPortal>
      <Popover.Target>
        <DisplayDate
          displayReset={displayReset}
          ref={setButton}
          setOpened={setOpened}
          value={value}
          label={label}
          variant={variant}
          size={size}
          setValue={setValue}
          onChange={handleOnChange}
        />
      </Popover.Target>
      <Popover.Dropdown p={0} ref={setCard}>
        <SuperDateRangePickerContainer onChange={handleOnChange} initialValue={initialValue} ref={ref} />
      </Popover.Dropdown>
    </Popover>
  );
};
