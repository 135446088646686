import { Group, Stack, Text, Tooltip } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FloatingEditButton } from '~/Components/Shared/FloatingEditButton';
import { isCommunication, isMeeting, isTask } from '~/server/services/activity/types';
import { dateTimeService } from '~/utils/date-time.service';
import { type Activity, type Card } from '~db/types';
import { ActivityIcon } from '../Communication/Icon';
import { useUpsertCommunicationStore } from '../Communication/upsert-communication.hook';
import { useUpsertMeetingStore } from '../Events/Meeting/upsert-meeting.hook';
import { useUpsertTaskStore } from '../Events/Task/upsert-task.hook';

const useEdit = ({ activity, card }: { activity: Activity; card: Card }) => {
  const { open: openCommunication } = useUpsertCommunicationStore();
  const { open: openMeeting } = useUpsertMeetingStore();
  const { open: openTask } = useUpsertTaskStore();
  const open = useCallback(() => {
    if (isCommunication(activity)) {
      openCommunication({ card, communication: activity, type: activity.type });
    }
    if (isMeeting(activity)) {
      openMeeting({ meeting: activity });
    }
    if (isTask(activity)) {
      openTask({ task: activity });
    }
  }, [activity, card, openCommunication, openMeeting, openTask]);
  return { open };
};

export const LeanActivityDisplay: React.FC<{ activity: Activity; card: Card }> = ({
  activity,
  card,
}) => {
  const { t } = useTranslation('activitiesComponent');
  const { open } = useEdit({ activity, card });
  const { hovered, ref } = useHover();
  const primaryText = activity.title ? activity.title : activity.description;
  const secondaryText = activity.title ? activity.description : undefined;

  return (
    <Stack gap={0} ref={ref} w="100%" pos={'relative'}>
      <Group gap={'xs'} wrap="wrap" style={{ rowGap: 0 }}>
        <FloatingEditButton handleClick={() => open()} show={hovered} />
        <ActivityIcon size={20} type={activity.type} />
        <Text lh={'xs'} fw={600}>
          {t(activity.type)}
        </Text>
        <Tooltip openDelay={400} label={dateTimeService.formatDateTime(activity.date)}>
          <Text c="dimmed" size="sm" style={{ whiteSpace: 'nowrap' }}>
            {dateTimeService.relativeTime(activity.date)}
          </Text>
        </Tooltip>
      </Group>
      <Text lineClamp={1} size="sm" fw={300}>
        {primaryText}
      </Text>
      {secondaryText && (
        <Text lineClamp={1} c="dimmed" size="xs">
          {secondaryText}
        </Text>
      )}
    </Stack>
  );
};
