import React, { Suspense } from 'react';

import { Skeleton } from '@mantine/core';
import { FormPhoneInputProps } from './types';

const FormPhoneInputLazy = React.lazy(() => import('./PhoneInputLazy'));

export const FormPhoneInput: React.FC<FormPhoneInputProps> = (props) => {
  return (
    <Suspense fallback={<Skeleton height={32} radius="sm" w="100%" />}>
      <FormPhoneInputLazy {...props} />
    </Suspense>
  );
};
