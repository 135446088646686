import { Checkbox, type CheckboxProps, Stack } from '@mantine/core';
import { useController, useFormContext } from 'react-hook-form';
import { type IBaseFormInputProps } from './shared/FormInputTypes';
import { Label } from './shared/Label';

type IFormCheckboxProps = CheckboxProps &
  IBaseFormInputProps & {
    labelInline?: boolean;
  };

export const FormCheckbox: React.FC<IFormCheckboxProps> = ({
  name,
  label,
  description,
  labelInline = false,
  onChange: handleChange,
  ...props
}) => {
  const { control, setValue } = useFormContext();
  const {
    field: { value, onChange, ...rest },
  } = useController({ name, control });

  return (
    <Stack gap={'sm'} mt={0}>
      {labelInline ? null : <Label fullWidth={false} label={label} description={description} />}
      <Checkbox
        label={labelInline ? label : undefined}
        description={labelInline ? description : undefined}
        id={name}
        checked={typeof value === 'boolean' ? value : false}
        onChange={(e) => {
          const isChecked = e.target.checked;
          onChange(isChecked);
          setValue(name, isChecked);
          handleChange?.(e);
        }}
        {...rest}
        {...props}
      />
    </Stack>
  );
};
