import { TimeInput, type TimeInputProps } from '@mantine/dates';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { type IBaseFormInputProps } from './shared/FormInputTypes';
import { Label } from './shared/Label';
import { parseFormErrorMessage } from './shared/error-message.util';

export const FormTimeInput: React.FC<TimeInputProps & IBaseFormInputProps> = ({
  name,
  label,
  description,
  required = false,
  ...props
}) => {
  const { control } = useFormContext();
  const {
    field: { value, ...rest },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const errorMessage = parseFormErrorMessage(error?.message);
  return (
    <TimeInput
      id={name}
      value={(value as string) || ''}
      label={label ? <Label label={label} description={description} required={required} /> : undefined}
      styles={{ label: { width: '100%' } }}
      error={errorMessage}
      {...rest}
      {...props}
    />
  );
};
