import { notifications } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';
import { create } from 'zustand';
import { type ICreateCommunication } from '~/server/services/activity/types';
import { api } from '~/trpc';
import { type Card, type CommunicationTypes } from '~db/types';

type UpsertCommunication = {
  card?: Card;
  communication: ICreateCommunication | null;
  opened: boolean;
  type?: CommunicationTypes;
  open: ({
    card,
    communication,
    type,
  }: {
    card: Card;
    communication?: ICreateCommunication;
    type?: CommunicationTypes;
  }) => void;
  close: () => void;
};

export const useUpsertCommunicationStore = create<UpsertCommunication>((set) => ({
  communication: null,
  opened: false,
  open: ({ card, communication, type }) => set({ opened: true, communication, card, type }),
  close: () => set({ opened: false, communication: null, card: undefined, type: undefined }),
}));

export const useUpsertCommunication = ({
  onSuccess,
}: {
  onSuccess?: () => void;
} = {}) => {
  const utils = api.useUtils();
  const { t } = useTranslation('activitiesComponent');

  const { mutateAsync: upsertCommunication } = api.communications.upsert.useMutation({
    onSuccess: async ({ activity: { type }, isNew }) => {
      notifications.show({
        title: isNew
          ? t('{{type}} created', { type: t(type) })
          : t('{{type}} updated', { type: t(type) }),
        message: isNew
          ? t('{{type}} created successfully', { type: t(type) })
          : t('{{type}} updated successfully', { type: t(type) }),
        color: 'green',
      });
      await utils.activities.search.invalidate();
      await utils.cards.search.invalidate({ columnVisibility: { lastActivity: true } });
      onSuccess?.();
    },
    onError: (error) => {
      notifications.show({
        title: t('Error'),
        message: error.message,
        color: 'red',
      });
    },
  });

  return { upsertCommunication };
};
