import { notifications } from '@mantine/notifications';
import { api } from '~/trpc';

type IActivityDelete = {
  title: string;
  message: string;
};

export const useActivityDelete = ({ title, message }: IActivityDelete) => {
  const utils = api.useUtils();

  const { mutate: deleteActivity, isPending: isLoadingDelete } = api.activities.delete.useMutation({
    onSuccess: async () => {
      notifications.show({
        title,
        message,
        color: 'green',
      });
      await utils.activities.search.invalidate();
      await utils.cards.search.invalidate({ columnVisibility: { lastActivity: true } });
    },
    onError: (error) => {
      notifications.show({
        title: 'Error',
        message: error.message,
        color: 'red',
      });
    },
  });

  return {
    deleteActivity,
    isLoadingDelete,
  };
};
