import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { create } from 'zustand';

const marketingPages = ['/', '/login', '/signup', '/terms-and-conditions', '/privacy-policy'];

export const useAppRouter = () => {
  const pathname = usePathname();

  const isAuthPage = pathname.startsWith('/login') || pathname.startsWith('/signup');
  const isMarketingPage = marketingPages.includes(pathname) || isAuthPage;

  return {
    isAppPage: !isMarketingPage && !isAuthPage,
    isAuthPage,
    isMarketingPage,
    pathname,
  };
};

export type TQueryParams = Record<string, string | undefined | null>;

const useQueryParamStore = create<{
  router?: ReturnType<typeof useRouter>;
  setQueryParams: (params: Partial<TQueryParams>, options?: { inherit?: boolean }) => void;
}>((set, get) => ({
  setRouter: (router: ReturnType<typeof useRouter>) => set({ router }),
  setQueryParams: (params, options = { inherit: true }) => {
    const urlSearchParams = options?.inherit
      ? new URLSearchParams(window.location.search)
      : new URLSearchParams();
    const pathname = window.location.pathname;

    let somethingChanged = false;
    Object.entries(params).forEach(([key, value]) => {
      // check if key value per is equal to existing value in the urlSearchParams
      if (urlSearchParams.has(key) && urlSearchParams.get(key) === value) {
        return;
      }
      // check if value is undefined, null or empty string and not in the urlSearchParams
      const isNothing = value === undefined || value === null || value === '';

      if (isNothing && !urlSearchParams.has(key)) {
        return;
      }

      somethingChanged = true;
      if (isNothing) {
        urlSearchParams.delete(key);
      } else {
        urlSearchParams.set(key, String(value));
      }
    });

    // replace since we don't want to build a history
    if (somethingChanged) {
      const search = urlSearchParams.toString();
      const query = search ? `?${search}` : '';
      const url = `${pathname}${decodeURIComponent(query)}`;
      window.history.replaceState({}, '', url);
    }
    return;
  },
}));

export const GlobalAppRouter = () => {
  const router = useRouter();

  useEffect(() => {
    useQueryParamStore.setState({ router });
  }, [router]);

  return null;
};

export const useQueryParams = <T extends TQueryParams>() => {
  const setQueryParams = useQueryParamStore((state) => state.setQueryParams);

  const searchParams = useSearchParams();

  const params = Object.fromEntries(searchParams) as T;

  return { setQueryParams, params };
};
