import { notifications } from '@mantine/notifications';
import { useParams, useSearchParams } from 'next/navigation';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRelatedFields } from '~/Components/Card/Modules/Relations/related-fields.hook';
import { useCardType } from '~/Components/Card/card-type.hook';
import { api } from '~/trpc';
import { useQueryParams } from '~/ui/hooks/app.router.hook';
import { tableUtils } from '~/ui/table/table.utils';
import { type CardType } from '~db/types';
import { cardsTableViewsService } from '../CardsTable/cards-table-view.service';
import { useCardTypes } from '../card-types.hook';

export const useCardsTableViews = (id?: string) => {
  const utils = api.useUtils();
  const { slug } = useParams<{ slug: string }>();
  const { t } = useTranslation('cardsPage');
  const { setQueryParams, params } = useQueryParams<{
    view?: string;
    sort?: string;
    filters?: string;
    columns?: string;
  }>();
  const selectedViewSlug = useSearchParams().get('view');
  const { cardType, isFetchingCardType, isLoadingCardType } = useCardType(slug);

  const { allRelatedFields } = useRelatedFields(cardType);

  const { data: cardView } = api.cardsTableViews.getById.useQuery(
    { id: id! },
    {
      enabled: Boolean(id),
      staleTime: 2 * 60 * 60 * 1000,
      gcTime: 5 * 60 * 60 * 1000,
    },
  );

  const cardViews = useMemo(() => cardType?.cardsTableViews ?? [], [cardType?.cardsTableViews]);
  const fields = useMemo(() => cardType?.fields ?? [], [cardType?.fields]);
  const { cardTypes } = useCardTypes();
  const views = useMemo(() => {
    if (!cardType) return [];

    const relatedCardTypeIds = [
      ...new Set(allRelatedFields.flatMap((f) => [f.cardTypeId, f.relatedCardTypeId])),
      ...(cardType?.cardLayouts.flatMap((l) => l.cardsTableViews.map((v) => v.cardTypeId)) ?? []),
    ];
    const relatedCardTypes = cardTypes?.filter(
      (c) => relatedCardTypeIds.includes(c.id) && c.id !== cardType?.id,
    );
    const relatedFields = allRelatedFields.filter(
      (f) => f.cardTypeId === cardType?.id || f.relatedCardTypeId === cardType?.id,
    );
    return cardsTableViewsService.getViewsColumns({
      cardViews,
      relatedFields,
      relatedCardTypes,
      allowEditRelatedValues: false,
      showLastActivity: true,
      cardType,
      t,
    });
  }, [cardViews, cardTypes, t, allRelatedFields, cardType]);

  const selectedView = useMemo(() => {
    return views.find((view) => view.slug === selectedViewSlug);
  }, [selectedViewSlug, views]);

  const {
    mutate: deleteCardView,
    error: errorDelete,
    isPending: isDeleteViewLoading,
  } = api.cardsTableViews.delete.useMutation({
    onSuccess: async () => {
      notifications.show({ message: t('The view has been deleted') });
      await utils.cardTypes.get.invalidate();
      await utils.cardTypes.getAll.invalidate();
    },
    onError: (error) => {
      notifications.show({ message: error?.message, color: 'red' });
    },
  });

  const {
    mutateAsync: upsertCardView,
    error: errorUpsert,
    isPending: isUpsertViewLoading,
  } = api.cardsTableViews.upsert.useMutation({
    onSuccess: async (data) => {
      notifications.show({ message: t('The view has been upserted') });
      utils.cardTypes.get.setData({ slug }, (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          cardsTableViews: prev.cardsTableViews.map((view) => {
            if (view.id === data.id) return data;
            return view;
          }),
        };
      });
      await utils.cards.search.invalidate({ cardTypeId: data.cardTypeId });
      await utils.cardTypes.get.invalidate({ slug });
      await utils.cardTypes.getAll.invalidate();
      const viewCardType = cardTypes?.find(({ id }) => id === data.cardTypeId);
      if (!viewCardType?.slug || slug === viewCardType?.slug) return;
      await utils.cardTypes.get.invalidate({ slug: viewCardType.slug });
    },
    onError: (error) => {
      notifications.show({ message: error?.message, color: 'red' });
    },
  });

  const setSelectedViewSlug = useCallback(
    (slug: string | null) => {
      if (!slug) return;
      const dbView = cardViews.find((v) => v.slug === slug);
      if (!dbView) return;
      const filters = dbView?.settings?.filters;
      const sort = dbView?.settings?.sort;
      const columns = dbView?.settings?.columns;
      if (slug) {
        setQueryParams({
          view: slug,
          sort: tableUtils.formatSort(sort),
          filters: tableUtils.formatFilters(filters),
          columns: tableUtils.formatColumns(columns),
        });
      }
    },
    [setQueryParams, selectedViewSlug, cardViews],
  );

  const createDefaultView = useCallback(
    async ({
      cardType,
      title,
      layoutId,
      slug,
    }: {
      cardType: CardType;
      title?: string;
      layoutId?: string;
      slug?: string;
    }) =>
      await upsertCardView({
        title: title ?? cardType.pluralName,
        description: t('Main View'),
        slug,
        cardTypeId: cardType.id,
        settings: {
          columns: {
            name: { order: 1, display: true },
          },
          sort: [{ field: 'createdAt', order: 'DESC' }],
        },
        ...(layoutId ? { layoutId } : {}),
        ...(!layoutId ? { slug: 'main' } : {}),
        order: 0,
      }),
    [t, upsertCardView],
  );

  return {
    createDefaultView,
    upsertCardView,
    errorDelete,
    errorUpsert,
    deleteCardView,
    setSelectedViewSlug,
    selectedView,
    views,
    cardView,
    cardViews,
    cardType,
    fields,
    params,
    isLoading:
      isUpsertViewLoading || isDeleteViewLoading || isFetchingCardType || isLoadingCardType || !cardType,
    isInitialLoading: isLoadingCardType || !cardType,
  };
};
