import { type ComboboxItem } from '@mantine/core';
import { type MRT_Cell, type MRT_ColumnDef } from 'mantine-react-table';
import { type ReactNode } from 'react';
import { SuperDateRangePicker } from '~/Components/Form/Dates/SuperDateRangePicker/SuperDateRangePicker';
import { type SuperDateRangeInitialValue } from '~/Components/Form/Dates/SuperDateRangePicker/types';
import { mapFieldTypeToDisplayComponent } from '~/Components/Settings/CardTypes/Fields/FieldsDisplay';
import { clerkClientUtils } from '~/core/auth/clerk.utils';
import { type FieldProps } from '~/server/services/templates/fields';
import { type FieldType } from '~db/types';
import { useMembershipsStore } from '../hooks/organization-memberships.hook';

type BaseModel = Record<string, unknown>;

type IGetTableCellInput<T extends BaseModel> = {
  cell: MRT_Cell<T>;
  type: FieldType;
  field?: FieldProps;
};

type IGetTableFilterInput<T> = {
  type: FieldType;
  accessorKey: string;
  options?: ComboboxItem[];
};

const mapTypeToSorting: Partial<Record<FieldType, boolean>> = {
  JSON: false,
  COLOR: false,
  IMAGE_URL: false,
};

const mapTypeToSize: Partial<Record<FieldType, number>> = {
  IMAGE_URL: 20,
  CHECKBOX: 45,
  AGE: 100,
};

const mapTypeToFilter: Partial<Record<FieldType, boolean>> = {
  JSON: false,
  IMAGE_URL: false,
};

const getCellOptions = <T extends BaseModel>(type: FieldType): Partial<MRT_ColumnDef<T>> => {
  return {
    enableSorting: mapTypeToSorting[type] ?? true,
    enableColumnFilter: mapTypeToFilter[type] ?? true,
    ...(mapTypeToSize[type] && { size: mapTypeToSize[type] }),
  };
};

const getCell = <T extends BaseModel>({
  cell,
  type,
  field,
}: IGetTableCellInput<T>): ReactNode | string => {
  const value = cell.getValue();

  const cellFormatter = mapFieldTypeToDisplayComponent[type];
  if (cellFormatter) {
    return cellFormatter({ value, field });
  }
  return value as string;
};

const getFilter = <T extends BaseModel>({
  type,
  accessorKey,
  options,
}: IGetTableFilterInput<T>): Partial<MRT_ColumnDef<T>> => {
  switch (type) {
    case 'DATE':
      return {
        sortingFn: 'datetime',
        filterVariant: 'date',
        accessorFn: (row) => {
          // convert to Date for sorting and filtering
          const sDay = new Date(row[accessorKey] as string);
          sDay.setHours(0, 0, 0, 0); // remove time from date (useful if filter by equals exact date)
          return sDay;
        },
        Filter({ column, table }) {
          const value = column.getFilterValue() as SuperDateRangeInitialValue;
          return (
            <SuperDateRangePicker
              initialValue={value}
              onChange={(values) => {
                table.setColumnFilters((prev) => {
                  const filter = prev.find((f) => f.id === column.id);
                  if (filter) {
                    filter.value = values;
                  } else {
                    prev.push({ id: column.id, value: values });
                  }
                  return prev;
                });
              }}
            />
          );
        },
      };
    case 'LOOKUP_USER': {
      const users = useMembershipsStore.getState().memberships?.data?.map((member) => ({
        label: clerkClientUtils.getFullName(member),
        value: member.publicUserData.userId!,
      }));
      return {
        filterVariant: 'multi-select',
        mantineFilterSelectProps: {
          data: users,
        },
      };
    }
    case 'CHECKBOX':
      return {
        filterVariant: 'checkbox',
      };
    case 'AGE':
      return {
        filterVariant: 'range',
      };
    case 'SINGLE_SELECT':
      return {
        filterVariant: 'multi-select',
        mantineFilterSelectProps: {
          data: options,
        },
      };
    case 'MULTI_SELECT':
      return {
        filterVariant: 'multi-select',
        mantineFilterSelectProps: {
          data: options ?? [],
        },
      };
    case 'CURRENCY':
      return {
        filterVariant: 'range',
      };
    case 'ACCOUNTING':
      return {
        filterVariant: 'range',
      };
    case 'NUMBER':
      return {
        filterVariant: 'range',
      };
    default:
      return {};
  }
};

const createColumn = <T extends BaseModel>({
  type,
  header,
  accessorKey,
  field,
  ...rest
}: Partial<MRT_ColumnDef<T>> & {
  type: FieldType;
  header: string;
  accessorKey: string;
  field?: FieldProps;
}): MRT_ColumnDef<T> => ({
  accessorKey,
  id: accessorKey,
  ...getFilter<T>({ type, accessorKey }),
  Cell({ cell }) {
    return getCell<T>({ cell, type, field });
  },
  header,
  ...rest,
});

export const appTableCellUtils = {
  getCellOptions,
  getCell,
  getFilter,
  createColumn,
};
