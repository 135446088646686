import { isNumber } from 'is-what';
import { type CardColumn, type ICardsTableViewSettings } from '../types';

const sortColumnsByOrder = (baseColumns: CardColumn[], viewSettings: ICardsTableViewSettings) => {
  if (!viewSettings.columns) return baseColumns;
  return [...baseColumns].sort((a, b) => {
    const first = viewSettings.columns?.[a.id!];
    const second = viewSettings.columns?.[b.id!];
    const firstOrder = isNumber(first?.order) ? first.order : Number.POSITIVE_INFINITY;
    const secondOrder = isNumber(second?.order) ? second.order : Number.POSITIVE_INFINITY;
    const firstDisplay = first?.display ? 0 : 1;
    const secondDisplay = second?.display ? 0 : 1;

    const diff = firstOrder - secondOrder + firstDisplay - secondDisplay;

    return diff;
  });
};

export const cardsTableViewUtils = {
  sortColumnsByOrder,
};
