import { ColorInput, type ColorInputProps } from '@mantine/core';
import { useController, useFormContext } from 'react-hook-form';
import { type IBaseFormInputProps } from '../shared/FormInputTypes';
import { Label } from '../shared/Label';
import { parseFormErrorMessage } from '../shared/error-message.util';

export const FormColorPicker: React.FC<ColorInputProps & IBaseFormInputProps> = ({
  name,
  label,
  description,
  required = false,
  ...props
}) => {
  const { control } = useFormContext();
  const {
    field: { value, ...rest },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const errorMessage = parseFormErrorMessage(error?.message);

  return (
    <ColorInput
      id={name}
      value={(value as string) || ''}
      label={label ? <Label label={label} description={description} required={required} /> : undefined}
      error={errorMessage}
      styles={{ label: { width: '100%' } }}
      {...rest}
      {...props}
    />
  );
};
